@charset 'utf-8';

.loding {
    max-width: 1000px;
    width: calc(100% - 40px);
}
.loading span {
    display: block;
    width: fit-content;
    margin: 80px auto;
    padding: 20px 30px;
    border: dashed 2px #CCA550;
    font-size: 20px;
}

.selectMessage {
    max-width: 1000px;
}
.selectMessage span {
    display: block;
    width: fit-content;
    margin: 80px auto;
    padding: 25px 30px 20px;
    border: solid 2px #CCA550;
    font-size: 15px;
}

.monsterpage {
    max-width: 1000px;
    width: calc(100% - 40px);
    margin: 0 auto;
    padding: 64px 20px 0;
}

.monsterpage__title {
    position: relative;
    margin: 0 auto 27px;
}
.monsterpage__title h4 {
    position: absolute;
    padding: 8px 10px 0;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.monsterpage__title h4 span {
    display: block;
    text-align: center;
    font-size: 13px;
    @media screen and (min-width:550px) {
        display: inline;
        font-size: 18px;
    }
}
.monsterpage__title > span {
    position: relative;
    z-index: -1;
}


.monster__name {
    display: block;
    display: flex;
    align-items: center;
    width: fit-content;
    margin: 0 auto;
    @media screen and (min-width:550px) {
        margin: 0;
        padding: 0 0 0 105px;
    }
}
.monster__name h3 {
    padding: 10px 0 0 5px;
}
.monster__name img {
    width: 32px;
    height: 32px;
}


.monster__topImage {
    position: relative;
    width: fit-content;
    margin: 34px auto 48px;
    @media screen and (min-width:750px) {
        margin-bottom: 56px;
    }
}
.monster__topImage::after {
    position: absolute;
    content: "";
    width: 200px;
    height: 137px;
    background-image: url(../../images/flame.png);
    background-size: contain;
    bottom: -5px;
    left: 0;
    z-index: -1;
}
@media screen and (min-width:750px) {
    .monster__topImage::after {
        width: 300px;
        height: 205px;
        bottom: -15px;
    }
}
.monster__topImage img {
    width: 200px;
    @media screen and (min-width:750px) {
        width: 300px;
    }
}

.monster__table {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    @media screen and (min-width: 750px){
        max-width: 1000px;
    }
}
.monster__table__title {
    display: block;
}
.monster__table__info {
    position: relative;
    width: fit-content;
    height: fit-content;
    margin: 0 0 0 auto;
    /* border-top: dashed 2px #7B7B7B; */
    border-bottom: dashed 2px #7B7B7B;
    @media screen and (min-width: 750px){
        margin: 0 10% 0 auto;
    }
}
.monster__table__info::after {
    position: absolute;
    content: "";
    width: calc(100% + 6px);
    height: 1px;
    border-top: dashed 2px #7B7B7B;
    top: -6px;
    left: -3px;
}
.monster__table__info span {
    display: block;
    font-size: 10px;
    color: #4E4D4D;
    @media screen and (min-width: 750px){
        font-size: 14px;
    }
}
.monster__table__info a {
    position: relative;
    font-size: 10px;
    @media screen and (min-width: 750px){
        font-size: 14px;
    }
}
.monster__table__info a::after {
    position: absolute;
    content: "";
    width: calc(100% + 4px);
    height: 1px;
    background-color: #4E4D4D;
    top: calc(100% - 8px);
    left: -2px ;
}

.selectButton {
    display: flex;
    width: fit-content;
    margin: 0 0 64px auto;
    border: solid 2px #8C7C5A;
}
.selectButton span {
    font-size: 15px;
    padding: 8px 20px 0;
    background-color: #CCA550;
    border-right: solid 2px #8C7C5A;
}
.selectButton select {
    font-size: 15px;
    text-align: center;
    background-color: #F6F1F1;
    border: none;
}

.dropRate {
    padding: 0 0 64px;
}
.dropRate__title {
    position: relative;
}
.dropRate__title h5 {
    position: absolute;
    padding: 8px 10px 0;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.dropRate__title span {
    position: relative;
    z-index: -1;
}
.dropRate__box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 600px;
    margin: 0 auto 20px;
    padding: 10px;
    gap: 10px 10px;
    background-color: #F5F5F5;
    border: solid 2px #CCA550;
    border-radius: 3px;
}
.dropRate__box a {
    padding: 3px 0 0;
    text-align: center;
}
.dropRate__box span {
    display: block;
    text-align: center;
    background: #92AAAA;
}
.dropRate__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 65px;
    background-color: #F6F1F1;
    border: solid 1px #8C7C5A;
}
.dropRate__item a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    font-size: 14px;
}
.Barroth {
    letter-spacing: 0;
}
.dropRate__item span {
    padding: 4px 0 0;
}
.dropRate__item:nth-of-type(3) span {
    background: #49B754;
}
.dropRate__item:nth-of-type(4) span {
    background: #4C89C8;
}
.dropRate__item:nth-of-type(5) span {
    background: #C36AE0;
}
.dropRate__item:nth-of-type(6) span {
    background: #EFB83A;
}
.dropRate__item:nth-of-type(7) span {
    background: #FE6867;
}

.ImageContainer {
    width: fit-content;
    margin: 0 auto 40px;
}
.ImageContainer img {
    width: 300px;
    @media screen and (min-width:750px) {
        width: 500px;
    }
}

.Barroth .dropRate__item:nth-of-type(6) a {
    letter-spacing: 0;
    font-size: 13px;
}
.Paolumu .dropRate__item:nth-of-type(6) a {
    letter-spacing: 0;
    font-size: 13px;
}
.Jyuratodus .dropRate__item:nth-of-type(6) a {
    letter-spacing: 0;
    font-size: 13px;
}
.BlackDiablos .dropRate__item:nth-of-type(5) a {
    letter-spacing: 0;
    font-size: 13px;
}
.Banbaro .dropRate__item:nth-of-type(5) a {
    letter-spacing: 0;
    font-size: 13px;
}