@charset 'utf-8';

.block__table {
  max-width: 600px;
  width: 100%;
  margin: 0 auto 12px;
  @media screen and (min-width: 750px) {
    max-width: 1000px;
    width: 80%;
  }
}

th {
    background-color: #D9D9D9;
}
td {
    width: 50px;
}
td:first-of-type {
    background-color: #D9D9D9;
}

.block__thead {
  font-size: 12px;
  @media screen and (min-width: 600px) and (max-width: 750px) {
    font-size: 14px;
  }
  @media screen and (min-width: 750px) {
    font-size: 15px;
  }
}

.block__tbody {
  font-size: 12px;
  @media screen and (min-width: 375px) {
    font-size: 12px;
  }
  @media screen and (min-width: 600px) and (max-width: 750px) {
    font-size: 14px;
  }
  @media screen and (min-width: 750px) {
    font-size: 17px;
  }
}

.block__table-row {
  text-align: center;
}

.empty__table-data {
  background-image: linear-gradient(
    to right top,
    transparent calc(50% - 0.5px),
    #000 50%,
    #000 calc(50% + 0.5px),
    transparent calc(50% + 1px)
  );
}
