@charset 'utf-8';

.hero {
    background-color: #3A332B;
    margin: -1px 0 0 0;
    padding: 18px 16px;
}

.hero__inner {
    max-width: 700px;
    margin: 0 auto;
    padding: 20px 0 19px;
    background-color: #4A3507;
    border: white solid 1px;
    border-radius: 5px;
    @media screen and (min-width:750px) {
        padding: 63px 0 50px;
    }
    @media screen and (min-width:1000px) {
        max-width: 790px;
    }
}
.hero__inner span {
    display: block;
    width: fit-content;
    margin: 0 auto;
    color: white;
    font-weight: 700;
    @media screen and (min-width:1000px) {
        max-width: 1000px;
    }
}
.hero__inner > span {
    position: relative;
    padding: 0 20px;
    font-weight: 700;
    @media screen and (min-width:750px) {
        padding: 0 120px;
    }
    @media screen and (min-width:1000px) {
        max-width: 1000px;
    }
}

.hero__inner__textbox {
    margin: 32px 0 0 0;
}
.hero__inner h2 {
    position: relative;
    font-size: 16px;
    font-weight: 700;
    color: white;
    /* text-align: center; */
    width: fit-content;
    height: fit-content;
    margin: 0 auto;
    z-index: 0;
}
.hero__inner h2::after {
    position: absolute;
    top: 7px;
    left: 50%;
    transform: translate( -50%, 0 );
    content: "";
    width: 105%;
    height: 70%;
    background-color: #968A6F;
    z-index: -1;
}
.hero__inner p {
    margin-top: 15px;
    padding: 0 25px;
    overflow-wrap: break-word;
    color: white;
    @media screen and (min-width:1000px) {
        max-width: 1000px;
    }
}

.iconHero {
    display: block;
    width: 62px;
    height: auto;
    margin: 0 auto;
    padding-bottom: 8px;
    @media screen and (min-width:750px) {
        position: absolute;
        top: -18px;
        left: 24px;
        margin: 0;
        padding: 0;
    }
}