.page__wrap {
  display: block;
  background: #f2f2f2;
  margin: 0 auto;
  padding: 10px 0;
}

.wapper {
  display: block;
  margin: 0 auto;
  width: 100%;
  @media screen and (min-width: 375px) {
    display: block;
    margin: 0 auto;
    width: 100%;
  }
  @media screen and (min-width: 600px) and (max-width: 750px) {
    display: block;
    margin: 0 auto;
    width: 100%;
  }
  @media screen and (min-width: 750px) {
    display: block;
    margin: 0 auto;
    max-width: 980px;
    width: 80%;
  }
}

.content__wrap {
  display: block;
  margin: 0 0 10px;
}

.c-title,
.title {
  background: #444;
  color: #fff;
  font-size: 10px;
  font-weight: 700;
  padding: 8px;
  @media screen and (min-width: 375px) {
    font-size: 10px;
    font-weight: 700;
    padding: 4px;
  }
  @media screen and (min-width: 600px) and (max-width: 750px) {
    font-size: 12px;
    font-weight: 700;
    padding: 6px;
  }
  @media screen and (min-width: 750px) {
    font-size: 14px;
    font-weight: 700;
    padding: 8px;
  }
}

.content {
  background: #fff;
  border-bottom: 1px solid #e2e2e2;
  border-left: 1px solid #e2e2e2;
  border-right: 1px solid #e2e2e2;
  color: #444;
  padding: 8px 10px;
}

.title__in__content {
  margin: 4px 0 4px;
  font-size: 1em;
  @media screen and (min-width: 375px) {
    margin: 4px 0 4px;
    font-size: 1.5em;
  }
  @media screen and (min-width: 600px) and (max-width: 750px) {
    margin: 10px 0 4px;
    font-size: 2em;
  }
  @media screen and (min-width: 750px) {
    margin: 10px 0 4px;
    font-size: 2em;
  }
}

.privacy__txt {
  margin: 0;
  padding: 0;
  font-size: 10px;
  @media screen and (min-width: 375px) {
    margin: 0;
    padding: 0;
    font-size: 10px;
  }
  @media screen and (min-width: 600px) and (max-width: 750px) {
    margin: 0;
    padding: 0;
    font-size: 14px;
  }
  @media screen and (min-width: 750px) {
    margin: 0;
    padding: 0;
    font-size: 16px;
  }
}

.privacy__list {
  margin: 0;
  padding: 0;
  font-size: 10px;
  @media screen and (min-width: 375px) {
    margin: 0;
    padding: 0;
    font-size: 10px;
  }
  @media screen and (min-width: 600px) and (max-width: 750px) {
    margin: 0;
    padding: 0;
    font-size: 14px;
  }
  @media screen and (min-width: 750px) {
    margin: 0;
    padding: 0;
    font-size: 16px;
  }
}

.privacy__list-item {
  margin: 5px 0px;
  font-size: 10px;
  @media screen and (min-width: 375px) {
    margin: 5px 0px;
    font-size: 10px;
  }
  @media screen and (min-width: 600px) and (max-width: 750px) {
    margin: 10px 0px;
    font-size: 14px;
  }
  @media screen and (min-width: 750px) {
    margin: 10px 0px;
    font-size: 16px;
  }
}

.privacy__clause {
  background: #232731;
  color: #fff;
  margin: 10px 0px;
  font-size: 10px;
  padding: 4px 8px;
  @media screen and (min-width: 375px) {
    margin: 10px 0px;
    font-size: 10px;
    padding: 4px 8px;
  }
  @media screen and (min-width: 600px) and (max-width: 750px) {
    margin: 10px 0px;
    font-size: 12px;
    padding: 4px 8px;
  }
  @media screen and (min-width: 750px) {
    margin: 10px 0px;
    font-size: 14px;
    padding: 4px 8px;
  }
}

.policies__link,
.company__link {
  margin: 0;
  padding: 0;
  font-size: 10px;
  color: #3381e8;
  @media screen and (min-width: 375px) {
    margin: 0;
    padding: 0;
    font-size: 10px;
  }
  @media screen and (min-width: 600px) and (max-width: 750px) {
    margin: 0;
    padding: 0;
    font-size: 14px;
  }
  @media screen and (min-width: 750px) {
    margin: 0;
    padding: 0;
    font-size: 16px;
  }
}
