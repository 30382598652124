@charset 'utf-8';

.header {
    position: relative;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    background-image: url(../images/bg_headfoot.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
}
.header::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(60, 51, 43, 0.9);
    z-index: -1;
}
.header a {
    color: white;
}

.header__inner {
    max-width: 1000px;
    width: calc( 100% - 48px );
    margin: 0 auto;
    padding: 24px 20px;
    @media screen and (min-width:750px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }
}

.header__title {
    display: flex;
    width: fit-content;
    margin: 0 auto;
}
@media screen and (min-width:750px) {
    .header__title {
        margin: 0;
    }
}
.header__title h1 {
    display: block;
    width: fit-content;
    margin: 0 auto;
}
.header__title h1 a {
    font-size: 18px;
    line-height: 1;
    @media screen and (min-width:750px) {
        font-size: 20px;
    }
}

.header__langButton {
    display: flex;
    padding: 0 0 0 8px;
}
.header__langButton a {
    position: relative;
    display: block;
    font-size: 14px;
    padding: 2% 4px;
    align-items: center;
    @media screen and (min-width:750px) {
        padding: 2.5% 4px 0;
    }
}
.header__langButton a::after {
    position: absolute;
    top: -8%;
    left: -2.5%;
    content: "";
    width: 100%;
    height: calc( 100% - 4px );
    border: 1px solid white;
    border-radius: 5px;
}
.header__langButton a:not(:first-child) {
    margin-left: 8px;
}

button {
    display: block;
    position: relative;
    color: white;
    width: fit-content;
    margin: 10px auto 0;
    @media screen and (min-width:750px) {
        margin: 0;
    }
}
button::after {
    position: absolute;
    top: 10%;
    left: -20px;
    content: "";
    width: 8px;
    height: 8px;
    border-bottom: 3px solid #CCA550;
    border-left: 3px solid #CCA550;
    transform: rotate(-45deg);
}