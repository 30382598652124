@charset 'utf-8';

.pulldown {
    width: 100%;
    padding: 20px 0 0;
}
.pulldown__inner {
    max-width: 380px;
    margin: 0 auto;
    padding: 5px;
    background-color: rgba(256, 256, 256, 0.3);
    border-radius: 5px;
    @media screen and (min-width:450px) {
        padding: 15px;
    }
    @media screen and (min-width:750px) {
        max-width: 600px;
    }
    @media screen and (min-width:1000px) {
        max-width: 1000px;
    }
}

.pulldown__inner ul {
    display: flex;
    flex-wrap: wrap;
    gap: 5px 8px;
}
.pulldown__inner li {
    display: flex;
    width: calc(50% - 4px);
    @media screen and (min-width:750px) {
        width: calc(33% - 4px);
    }
    @media screen and (min-width:1000px) {
        width: calc(25% - 6px);
    }
}
.pulldown__inner a {
    display: flex;
    font-size: 14px;
    /* line-height: normal; */
}
.pulldown__inner img {
    width: 23px;
    height: 23px;
    align-items: center;
}
.pulldown__inner span {
    padding: 0 0 0 8px;
    color: white;
    font-size: 14px;
    line-height: normal;
    align-items: center;
    @media screen and (min-width:400px) {
        font-size: 16px;
    }
}