@charset 'utf-8';
@import url("https://fonts.googleapis.com/css2?family=Anek+Kannada:wght@300;400;500;700&display=swap");

body {
  font-family: "Anek Kannada", sans-serif;
  margin: 0;
  padding: 0;
  background: white;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
dl,
a {
  margin: 0;
  padding: 0;
  line-height: 1.5;
  letter-spacing: 0.1em;
  color: #152a44;
  font-size: 16px;
  font-weight: 500;
}
h1 {
    all: unset;
}
h2 {
  font-size: 20px;
  letter-spacing: 3px;
  font-weight: 400;
}
h3 {
    font-size: 20px;
    @media screen and (min-width:750px) {
        font-size: 22px;
    }
}
h4 {
    font-size: 13px;
    @media screen and (min-width:750px) {
        font-size: 17px;
    }
}
h5 {
    font-size: 16px;
}

a {
  text-decoration: none;
  color: #152a44;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}
li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

table {
  display: table;
  border-collapse: collapse;
  text-indent: initial;
  box-sizing: content-box;
  border-spacing: 2px;
  border-color: black;
  margin: 0;
}
th {
    font-weight: 400;
}
td {
    font-weight: 300;
}

button {
    all: unset;
}
menu {
    all: unset;
}