@charset 'utf-8';

.main {
    width: 100%;
}

.main__inner {
    max-width: 600px;
    width: calc(100% - 40px);
    padding: 40px 20px;
    margin: 0 auto;
    @media screen and (min-width:750px) {
        max-width: 1000px;
    }
}

.main__inner__title {
    display: block;
    display: flex;
    align-items: center;
    width: fit-content;
    margin: 0 auto;
}
.iconTitle {
    width: 34px;
    height: auto;
    margin: -10px 5px 0 0;
}

.main__inner ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px 20px;
    margin: 40px 0 0;
}
.main__inner ul img {
    width: 90px;
    height: auto;
    @media screen and (min-width:600px) {
        width: 112px;
    }
    @media screen and (min-width:1000px) {
        width: 150px;
    }
}
.main__inner li:nth-child(4n) {
    margin: 0;
}
.main__inner li a {
    display: flex;
    flex-direction: column;
}
.main__inner li a span {
    font-size: 12px;
    letter-spacing: 0.05rem;
    margin: 6px auto 0;
    @media screen and (min-width:600px) {
        font-size: 15px;
    }
}
.main__inner li a > span:nth-of-type(2) {
    margin: -4px auto 0;
}