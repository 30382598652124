@charset 'utf-8';

.footer {
    position: relative;
    width: 100%;
    background-image: url(../images/bg_headfoot.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
}
.footer::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(60, 51, 43, 0.9);
    z-index: -1;
}

.footer__inner {
    padding: 50px 20px;
}

.footer__inner > span {
    display: flex;
    width: fit-content;
    margin: 0 auto 24px;
}
.footer__inner a {
    position: relative;
    font-size: 15px;
    color: white;
    line-height: normal;
    @media screen and (min-width:750px) {
        font-size: 16px;
    }
}

.decolation__line {
    width: 1px;
    height: 20px;
    margin: 0 12px;
    background-color: white;
}
.footer .decolation__line {
    margin: 2px 12px;
}

.footer small {
    display: block;
    width: fit-content;
    margin: 0 auto;
    color: white;
}