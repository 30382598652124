@charset 'utf-8';

.deco {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
}
.deco.short {
    max-width: 200px;
    width: 50%;
}
.deco__inner {
    padding: 24px 20%;
    display: flex;
    @media screen and (min-width:550px) {
        padding: 24px 105px;
    }
}
.deco__inner.short {
    padding: 15px 20px;
}

.deco__border {
    display: block;
    width: 100%;
    height: 2px;
    margin: auto;
    background-color: #CCA550;
}
.deco__square {
    position: relative;
    left: -4px;
    display: block;
    width: 10px;
    height: 10px;
    background-color: #CCA550;
    transform: rotate( 45deg );
}
.deco__square::after {
    position: absolute;
    top: -4px;
    left: 4px;
    content: "";
    width: 10px;
    height: 10px;
    background-color: #CCA550;
}
.deco__square::before {
    position: absolute;
    top: -2px;
    left: 2px;
    content: "";
    width: 10px;
    height: 10px;
    background-color: white;
}

.deco__square__last {
    position: relative;
    left: 4px;
    display: block;
    width: 10px;
    height: 10px;
    background-color: #CCA550;
    transform: rotate( 45deg );
}
.deco__square__last::after {
    position: absolute;
    top: 4px;
    left: -4px;
    content: "";
    width: 10px;
    height: 10px;
    background-color: #CCA550;
}
.deco__square__last::before {
    position: absolute;
    top: 2px;
    left: -2px;
    content: "";
    width: 10px;
    height: 10px;
    background-color: white;
}